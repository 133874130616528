

import {IonContent, IonItem, IonPage} from "@ionic/vue";
import { execute } from "@/mixins/LoadingMixin";
import router from "@/router";
import { User } from "@/models/UserModel";
import {computed, ref} from "vue";
import FixedFooter from '@/components/FixedFooter.vue'
import SearchBar from '@/components/SearchBar.vue'
import {defineComponent} from "@vue/runtime-core";
import {useStore} from "vuex";
import {FooterButtonsBuilder} from "@/mixins/FooterButtonsBuilder";
import DirectionIndicator from "@/components/DirectionIndicator.vue";
import {getUsers} from "@/services/UserService";

export default defineComponent({
    name: 'Users',
    components: {
        DirectionIndicator,
        IonContent,
        IonPage,
        IonItem,
        FixedFooter,
        SearchBar
    },
    async ionViewWillEnter() {
        this.users = [];
        await this.store.dispatch('app/setPageTitle', this.language.users);
        await execute( async () => {
            this.users = await this.searchUsers(this.searchText);
        }, this.language.errors.gettingUsers);
    },
    setup() {
        const users = ref([] as User[]);
        const searchBar = ref();
        const store = useStore();
        const language = computed(() => store.getters['app/language']);
        const searchText = ref("");
        const userStatus = ref('active');

        const editUser = async (id: string) => {
            router.push({ name: 'editUser', params: { id: id} });
        }

        const addUser = async () => {
            router.push({ name: 'addUser' });
        }
        
        const searchUsers = async (search: string) => {
            users.value = await getUsers(userStatus.value, search);
            searchText.value = search;
            return users.value;
        }
        
        const toggleArchived = async (show: boolean) => {
            userStatus.value = show ? 'all' : 'active';
            users.value = await getUsers(userStatus.value, searchText.value);
        }

        return {
            store,
            language,
            users,
            editUser,
            addUser,
            userStatus,
            searchUsers,
            footerButtons: computed(() => new FooterButtonsBuilder().addClearButton(false, () => addUser(), language.value.addUser).create()),
            searchBar,
            searchText,
            toggleArchived
        }
    }
});
