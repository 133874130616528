<template>
    <ion-page>
        <ion-content :fullscreen="true" class="ion-no-padding">
            <SearchBar ref="searchBar" 
                       @toggleArchived="toggleArchived"
                       @search="searchUsers" 
                       :show-more-button="true"
                       :toggle-checked="userStatus === 'all'"/>
            <h5 class="ion-padding" v-if="users.length === 0">{{ language.noUsersYet }}</h5>
            <ion-item lines="full" mode="md" v-for="user of users" :key="user.id" @click="editUser(user.id)">
                <ion-label>
                    <h2 :class="{'archived-text': user.archived}">{{ user.username }}
                        <span v-if="user.archived" class="archived-text"> - {{ language.archived }}</span>
                        <span v-else-if="!user.active" class="locked-text"> - {{ language.locked }}</span>
                    </h2>
                    <p>{{ user.partner?.name }}</p>
                </ion-label>
                <DirectionIndicator class="edit-forward" :is-ltr-forward="true" />
            </ion-item>
        </ion-content>
        <FixedFooter :buttons="footerButtons"/>
    </ion-page>
</template>

<script lang="ts">

import {IonContent, IonItem, IonPage} from "@ionic/vue";
import { execute } from "@/mixins/LoadingMixin";
import router from "@/router";
import { User } from "@/models/UserModel";
import {computed, ref} from "vue";
import FixedFooter from '@/components/FixedFooter.vue'
import SearchBar from '@/components/SearchBar.vue'
import {defineComponent} from "@vue/runtime-core";
import {useStore} from "vuex";
import {FooterButtonsBuilder} from "@/mixins/FooterButtonsBuilder";
import DirectionIndicator from "@/components/DirectionIndicator.vue";
import {getUsers} from "@/services/UserService";

export default defineComponent({
    name: 'Users',
    components: {
        DirectionIndicator,
        IonContent,
        IonPage,
        IonItem,
        FixedFooter,
        SearchBar
    },
    async ionViewWillEnter() {
        this.users = [];
        await this.store.dispatch('app/setPageTitle', this.language.users);
        await execute( async () => {
            this.users = await this.searchUsers(this.searchText);
        }, this.language.errors.gettingUsers);
    },
    setup() {
        const users = ref([] as User[]);
        const searchBar = ref();
        const store = useStore();
        const language = computed(() => store.getters['app/language']);
        const searchText = ref("");
        const userStatus = ref('active');

        const editUser = async (id: string) => {
            router.push({ name: 'editUser', params: { id: id} });
        }

        const addUser = async () => {
            router.push({ name: 'addUser' });
        }
        
        const searchUsers = async (search: string) => {
            users.value = await getUsers(userStatus.value, search);
            searchText.value = search;
            return users.value;
        }
        
        const toggleArchived = async (show: boolean) => {
            userStatus.value = show ? 'all' : 'active';
            users.value = await getUsers(userStatus.value, searchText.value);
        }

        return {
            store,
            language,
            users,
            editUser,
            addUser,
            userStatus,
            searchUsers,
            footerButtons: computed(() => new FooterButtonsBuilder().addClearButton(false, () => addUser(), language.value.addUser).create()),
            searchBar,
            searchText,
            toggleArchived
        }
    }
});
</script>

<style scoped>
.locked-text {
    color: red;
}

ion-item ion-label h2.archived-text, ion-item ion-label h2 span.archived-text {
    color: var(--color-step-600);
}
</style>